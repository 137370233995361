import MeetingBaseService from './base/MeetingBaseService';

export class MeetingBookApi extends MeetingBaseService {
    constructor() {
        super("meetingBook");
    }
    /**
    * 会议室预约记录信息
    *  let params = {
        'roomId': id,
        'start': '' ,
        'end': '' 
    }
    */
    public getRoomBookDetail(id: any, startDate: any, endDate: any): Promise<any> {
        let params = {
            'roomId': id,
            'start': startDate == null ? '' : startDate,
            'end': endDate == null ? '' : endDate
          }
        return this.request('eventsList', this.rest.METHOD_GET, params);
    }
    /**
     * 我的预约记录列表
     *   let params = {
            'page': page,
            'size': size,
            'userId': userId,//当前用户标识
            'name': '',//会议室名称关键字
            'startDate': '',//开始日期
            'endDate': ''//结束日期
        }
    */
    public getBookList(params: any): Promise<any> {
        return this.request('list', this.rest.METHOD_POST, params);
    }

    /**
    * 获取会议室预定详情
    *   let params = {
           'id': id
       }
   */
    public getBookDetail(params: any): Promise<any> {
        return this.request('detail', this.rest.METHOD_GET, params);
    }

    /**
    * 会议室预约记录状态变更
    *  let params = {
        'id': id,//会议室预定记录标识
        'status': status,//状态：0 预约中 1 取消 2 结束
        'cancelReason': cancelReason// 取消原因(取消操作时填写)
    }
    */
    public updateBookDetail(params: any): Promise<any> {
        return this.request('status/update?id='+params.id+"&status="+params.status+"&cancelReason="+params.cancelReason, this.rest.METHOD_POST, {});
    }
    /**
    * 提交会议室预定
    * let params = {
            'id': id,//主键
            'name': name,//名称
            'roomId': roomId,//会议室Id
            'roomName': roomName,//会议室名称：全称
            'userId': userId,//预订人Id
            'userName': userName,
            'status': satatus,//预订状态：1取消 0 预定中，默认 0
            'remark': remark,//备注
            'isClean': isClean,//用后是否清洁
            'beginTime': beginTime,//预约开始时间
            'endTime': endTime,//预订结束时间
            'meetingUsers': meetingUsers
        }
    */
    public saveRoomBook(params: any): Promise<any> {
        return this.request('save', this.rest.METHOD_POST, params);
    }
}
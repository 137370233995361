import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "left" }
const _hoisted_2 = { class: "title-row" }
const _hoisted_3 = { class: "content-row" }
const _hoisted_4 = { class: "content-row" }
const _hoisted_5 = {
  key: 0,
  class: "content-row"
}
const _hoisted_6 = {
  key: 0,
  class: "right"
}
const _hoisted_7 = {
  key: 1,
  class: "right"
}
const _hoisted_8 = {
  key: 2,
  class: "right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "item",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.detailHandler(_ctx.RoomBookCardObj)))
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, _toDisplayString(_ctx.RoomBookCardObj.roomName), 1),
      _createVNode("div", _hoisted_3, "使用日期：" + _toDisplayString(_ctx.RoomBookCardObj.beginTime.slice(0, 10)), 1),
      _createVNode("div", _hoisted_4, "使用时间：" + _toDisplayString(_ctx.RoomBookCardObj.beginTime.slice(11)) + "-" + _toDisplayString(_ctx.RoomBookCardObj.endTime.slice(11)), 1),
      (_ctx.RoomBookCardObj.name!=null&&_ctx.RoomBookCardObj.name!='')
        ? (_openBlock(), _createBlock("div", _hoisted_5, "会议主题：" + _toDisplayString(_ctx.RoomBookCardObj.name), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.RoomBookCardObj.statusName!='取消预定'&&_ctx.RoomBookCardObj.statusName!='预订中')
      ? (_openBlock(), _createBlock("div", _hoisted_6, _toDisplayString(_ctx.RoomBookCardObj.statusName), 1))
      : _createCommentVNode("", true),
    (_ctx.RoomBookCardObj.statusName=='取消预定')
      ? (_openBlock(), _createBlock("div", _hoisted_7, "已取消"))
      : _createCommentVNode("", true),
    (_ctx.RoomBookCardObj.statusName=='预订中')
      ? (_openBlock(), _createBlock("div", _hoisted_8, "预订中"))
      : _createCommentVNode("", true)
  ]))
}
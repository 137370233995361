
import { menuController, modalController, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";
//时间格式化
import moment from "moment";
import Utils from "@/utils/Utils";
//组件
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import AvatarList from "@/components/AvatarList.vue";
import BaseService from "@/services/base/BaseService";

export default defineComponent({
  name: "MyBookSearchPanel",
  props: {},
  components: {
    AvatarList,
  },
  emits: ["on-close"],
  data() {
    return {
      isCondition: false,
      searchNameArr: new Array(0),
      searchName: "",
      //----筛选条件字段--------------
      searchPanelForm: {
        id: "",
        searchName: "", //搜索名称
        keyName: "", //任务名称
        roomName: "", //会议室名称
        startTimeStart: "", //开始时间
        startTimeEnd: "",
        systemId: "", //来源（多选）不传默认全部
        joinArr: [], //参与人
        queryType: "roombook",
        isSelected: "false",
      },
      baseService: new BaseService(),
    };
  },
  methods: {
    subStringUserName(name: string) {
      return Utils.subStringUserName(name, 2);
    },
    //关闭
    onClose() {
      menuController.close();
    },
    //参与人
    async selectJoiner() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: this.searchPanelForm.joinArr,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.searchPanelForm.joinArr = data.data;
      }
    },
    keyNameHandler(ev: any) {
      const keyCode = window.event ? ev.keyCode : ev.which;
      if (keyCode === 13) {
        this.confirm();
      }
    },
    roomNameHandler(ev: any) {
      const keyCode = window.event ? ev.keyCode : ev.which;
      if (keyCode === 13) {
        this.confirm();
      }
    },
    //删除所选的人
    deleteUser(type: any, item: any, index: number) {
      if (type == "Joiner") {
        //参与人
        this.searchPanelForm.joinArr.splice(index, 1);
      }
    },
    //重置时间
    resetTime(type: any) {
      if (type == "startTime") {
        this.searchPanelForm.startTimeStart = "";
        this.searchPanelForm.startTimeEnd = "";
      }
    },
    //时间处理-创建人处理
    timeFromat() {
      if (this.searchPanelForm.startTimeStart) {
        this.searchPanelForm.startTimeStart = moment(
          this.searchPanelForm.startTimeStart
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.startTimeEnd != "") {
        this.searchPanelForm.startTimeEnd = moment(
          this.searchPanelForm.startTimeEnd
        ).format("yyyy-MM-DD HH:mm:ss");
      }
    },
    //确定检索
    confirm() {
      if (Utils.isInCludeEmoji(this.searchPanelForm.keyName)) {
        Utils.presentToastWarning("会议主题不可以包含表情");
        return;
      }
      this.searchPanelForm.keyName = this.baseService.trim(
        this.searchPanelForm.keyName
      );
      if (Utils.isInCludeEmoji(this.searchPanelForm.roomName)) {
        Utils.presentToastWarning("会议室名称不可以包含表情");
        return;
      }
      this.searchPanelForm.roomName = this.baseService.trim(
        this.searchPanelForm.roomName
      );
      this.timeFromat();
      this.searchPanelForm.isSelected = "true";
      this.$emit("on-close", this.searchPanelForm);
      this.onClose();
    },
    //重置检索条件
    reset() {
      this.searchPanelForm = {
        id: "",
        searchName: "", //搜索名称
        keyName: "", //会议主题
        roomName: "", //会议室名称
        startTimeStart: "", //创建时间
        startTimeEnd: "",
        systemId: "", //来源（多选）不传默认全部
        joinArr: [], //参与人
        queryType: "roombook",
        isSelected: "false",
      };
      this.$emit("on-close", this.searchPanelForm);
      this.onClose();
    },

    //打开条件名称框
    openCondition() {
      this.isCondition = true;
    },
    //关闭条件名称框
    CloseDialog() {
      this.isCondition = false;
    },
    //渲染查询条件数据
    initSeachFormData(params: any) {
      console.log(params);
    },
    avatarData(item: any) {
      var users: any = [];
      let obj = { name: item.name, img: item.photo, id: item.userId };
      users.push(obj);
      return users;
    },
  },
});


import { defineComponent } from "vue";
import { popoverController, modalController } from "@ionic/vue";

export default defineComponent({
  name: "RoomBookCard",
  components: {},
  props: {
    RoomBookCardObj: Object,
  },
  emits: ["on-detail"],
  data() {
    return {};
  },
  methods: {
    detailHandler(item: any) {
      this.$emit("on-detail", { item: item });
    },
  },
});

import { MeetingBookApi } from '@/api/meeting/MeetingBookApi';

export class MeetingBookService {
    private api = new MeetingBookApi();

    public getRoomBookDetail(id: any, startDate: any, endDate: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getRoomBookDetail(id, startDate, endDate).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getBookList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getBookList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getBookDetail(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getBookDetail(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public updateBookDetail(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.updateBookDetail(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public saveRoomBook(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.saveRoomBook(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}